import React from "react";
import Sale from "./Sale";

export default function Home() {
  return (
    <div className="home">
      <Sale />
    </div>
  );
}
