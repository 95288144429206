import React from "react";
import { Box, Typography } from "@material-ui/core";
import Navbar from "./../components/Navbar";
import { Link } from "react-router-dom";
// import { UserContext } from "./../context/UserContext";
import HelmetTemplate from "../components/HelmetTemplate";

export default function About() {
  // const { userState } = useContext(UserContext);

  return (
    <div className="about">
      <HelmetTemplate
        title="このアプリについて"
        description="amazoncomicで取り扱っている情報の参照元や、お問い合わせ先についての記載。"
      />
      <Navbar />
      <Box
        className={"contents static"}
        minHeight="100vh"
        justifyContent="center"
        textAlign="left"
      >
        <Typography component="h1" variant="h5">
          このアプリについて
        </Typography>
        <section>
          <Typography component="p" variant="body2">
            当サイト「amazoncomic」は
            <Link to="https://www.amazon.co.jp/" target="_blank">
              Amazon.co.jp
            </Link>
            で販売している電子書籍媒体コミックのジャンル別売れ筋ランキングやセール情報を紹介しているサイトです。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            更新頻度
          </Typography>
          <Typography component="p" variant="body2">
            毎日1時間ごとに最新のデータに更新されます。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            セール情報
          </Typography>
          <Typography component="p" variant="body2">
            セール、キャンペーン情報はコミック媒体に限らないkindle書籍全体のキャンペーン情報から取得しています。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            情報参照元
          </Typography>
          <Typography component="p" variant="body2">
            このサイトのすべての情報は、
            <Link to="https://www.amazon.co.jp/" target="_blank">
              Amazon
            </Link>
            が配信している
            <Link
              to="https://webservices.amazon.com/paapi5/documentation/"
              target="_blank"
            >
              Amazon Product Advertising API
            </Link>
            から正規の認証済みアカウントを通して取得しています。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            お問い合わせ
          </Typography>
          <Typography component="p" variant="body2">
            お問い合わせはamazoncomicjp@gmail.comまで。
          </Typography>
        </section>
      </Box>
    </div>
  );
}
