import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "./../context/UserContext";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import iconAC from "./../images/logo120.png";
import { ReactComponent as IconAddToHome } from "./../images/iconAddToHome.svg";

// Detects if device is on iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
// Detects if device is in standalone mode
const isInStandaloneMode = () =>
  "standalone" in window.navigator && window.navigator.standalone;

// Checks if should display install popup notification:

export default function Aths() {
  const [state] = React.useState({
    open: false,
    Transition: Fade,
  });
  const { userState, setUserState } = useContext(UserContext);
  document.addEventListener("DOMContentLoaded", function () {
    if (isIos() && !isInStandaloneMode()) {
      setUserState({ ...userState, showInstallMessage: true });
    }
  });
  const handleClose = () => {
    setUserState({
      ...userState,
      showInstallMessage: false,
    });
  };
  const useStyles = makeStyles((theme) => ({
    close: {
      padding: theme.spacing(0.5),
    },
  }));
  const classes = useStyles();
  const contents = userState.showInstallMessage ? (
    <Snackbar
      className="flex"
      open={userState.showInstallMessage}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      message={
        <React.Fragment>
          <img src={iconAC} alt="acをホームスクリーンに追加" />
          <span>
            アプリをiPhoneにインストールできます。
            <IconAddToHome />
            をタップし「ホーム画面に追加」を選択してください。
          </span>
        </React.Fragment>
      }
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
    ></Snackbar>
  ) : (
    ""
  );

  return <div className="aths">{contents}</div>;
}
