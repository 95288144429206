import React from "react";
import CachedIcon from "@material-ui/icons/Cached";

export default function Loading() {
  return (
    <div className="loading">
      <div>
        <CachedIcon />
        <p>ロード中です</p>
      </div>
    </div>
  );
}
