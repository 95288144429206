import firebase from "firebase/app";
// import "firebase/database";
import "firebase/firebase-storage";
// import "firebase/firebase-functions";
// const functions = require("firebase-functions");
const config = {
  apiKey: "AIzaSyAePXKOTblN3nRUHM18taHafzOS9VoyaaY",
  authDomain: "kindle-2d0bc.firebaseapp.com",
  databaseURL: "https://kindle-2d0bc.firebaseio.com",
  projectId: "kindle-2d0bc",
  storageBucket: "kindle-2d0bc.appspot.com",
  messagingSenderId: "89594007282",
  appId: "1:89594007282:web:8f890e2899ff83e344ca6b",
  measurementId: "G-YKVL49JHHW",
};

// const config = {
//   apiKey: firebase.functions().config().amazoncomic.apikey,
//   authDomain: firebase.functions().config().amazoncomic.authdomain,
//   databaseURL: firebase.functions().config().amazoncomic.databaseurl,
//   projectId: firebase.functions().config().amazoncomic.projectid,
//   storageBucket: firebase.functions().config().amazoncomic.storagebucket,
//   messagingSenderId: firebase.functions().config().amazoncomic
//     .messagingsenderid,
//   appId: firebase.functions().config().amazoncomic.appid,
//   measurementId: firebase.functions().config().amazoncomic.measurementid,
// };
// axios.get("/__firebase/init.json").then((res) => {
//   const config2 = res.json();
//   console.log("config2", config2);
//   firebase.initializeApp(config2);
// });
firebase.initializeApp(config);
// export const db = firebase.database();
// export const functions = firebase.functions();
export const storage = firebase.storage();
