import React, { useContext } from "react";
import { UserContext } from "./../context/UserContext";
import moment from "moment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import noimage from "../images/noimage.png";
import TagManager from "react-gtm-module";
import { EventAvailable } from "@material-ui/icons";

export default function EventWindow() {
  const { userState } = useContext(UserContext);
  const eventWindowDom = React.createRef();
  let contentImg = null;
  let description = null;
  const clickLinkToOutside = (event, target) => {
    console.log(`clickLinkToOutside event:${event},target:${target}`);
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };
  const $eventA = document.querySelectorAll(".eventWindow a");
  if (userState.currentContent) {
    for (let i = 0; i < $eventA.length; i++) {
      $eventA[i].addEventListener("click", () => {
        clickLinkToOutside(
          "clickLinkInEventWindow",
          userState.currentContent.ItemInfo.Title.DisplayValue
        );
      });
    }
  }
  const contents = () => {
    if (userState.isEventWindow) {
      contentImg = userState.currentContent.Images ? (
        <a
          href={userState.currentContent.DetailPageURL}
          target="_blank"
          rel="noopener"
        >
          <img
            src={userState.currentContent.Images.Primary.Medium.URL}
            alt={userState.currentContent.ItemInfo.Title.DisplayValue}
          />
        </a>
      ) : (
        <img src={noimage} alt="画像が用意されていません" />
      );
      description = (
        <div>
          <div className="eventWindow__moreInfo__description__title">
            <a
              href={userState.currentContent.DetailPageURL}
              target="_blank"
              rel="noopener"
            >
              {userState.currentContent.ItemInfo.Title.DisplayValue}
            </a>
          </div>
          <div className="eventWindow__moreInfo__description__price text__right">
            {userState.currentContent.Offers.Listings[0].Price.Amount}円
          </div>
          <table>
            <tbody>
              {userState.currentContent.ItemInfo.ContentInfo.PublicationDate ? (
                <tr className="eventWindow__moreInfo__description__date">
                  <td>出版日 : </td>
                  <td className="text__right">
                    {moment(
                      userState.currentContent.ItemInfo.ContentInfo
                        .PublicationDate.DisplayValue
                    ).format("YYYY年MM月DD日")}
                  </td>
                </tr>
              ) : (
                <tr className="eventWindow__moreInfo__description__date"></tr>
              )}

              {userState.currentContent.BrowseNodeInfo ? (
                <tr className="eventWindow__moreInfo__description__rank">
                  <td>売上ランク : </td>
                  <td className="text__right">
                    {
                      userState.currentContent.BrowseNodeInfo.WebsiteSalesRank
                        .SalesRank
                    }
                    位
                  </td>
                </tr>
              ) : (
                <tr className="eventWindow__moreInfo__description__rank"></tr>
              )}
              {userState.currentContent.ItemInfo.ContentInfo.PagesCount ? (
                <tr className="eventWindow__moreInfo__description__pageCount">
                  <td>総ページ数 : </td>
                  <td className="text__right">
                    {
                      userState.currentContent.ItemInfo.ContentInfo.PagesCount
                        .DisplayValue
                    }
                    ページ
                  </td>
                </tr>
              ) : (
                <tr className="eventWindow__moreInfo__description__pageCount"></tr>
              )}
            </tbody>
          </table>
          <div className="eventWindow__moreInfo__description__btn">
            <a
              href={userState.currentContent.DetailPageURL}
              target="_blank"
              rel="noopener"
            >
              <span
                onClick={() => {
                  clickLinkToOutside(
                    "clickDetailedButton",
                    userState.currentContent.ItemInfo.Title.DisplayValue
                  );
                }}
              >
                詳細を見る
              </span>
              <ExitToAppIcon
                onClick={() => {
                  clickLinkToOutside(
                    "clickDetailedButton",
                    userState.currentContent.ItemInfo.Title.DisplayValue
                  );
                }}
              />
            </a>
          </div>
        </div>
      );
      return (
        <div className="eventWindow active" ref={eventWindowDom}>
          <div className="eventWindow__moreInfo">
            <div
              className="eventWindow__moreInfo__img"
              onClick={() => {
                clickLinkToOutside(
                  "clickDetailedImg",
                  userState.currentContent.ItemInfo.Title.DisplayValue
                );
              }}
            >
              {contentImg}
            </div>
            <div
              className="eventWindow__moreInfo__description"
              onClick={() => {
                console.log("test");
                // clickLinkToOutside(
                //   "clickDetailedTitle",
                //   userState.currentContent.ItemInfo.Title.DisplayValue
                // );
              }}
            >
              {description}
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="eventWindow" ref={eventWindowDom}></div>;
    }
  };

  return contents();
}

// export function closeWindow() {
//   console.log('closeWindow');
//   const { userState, setUserState } = useContext(UserContext);
//   setUserState({
//     ...userState,
//     data:{
//       ...userState.data,
//       isEventWindow: false,
//       currentContent: null,
//       eventWindowHeight: null,
//     }
//   })
// }
