import React from "react";
import { Box, Typography } from "@material-ui/core";
import Navbar from "./../components/Navbar";
import { Link } from "react-router-dom";
// import { UserContext } from "./../context/UserContext";
import HelmetTemplate from "../components/HelmetTemplate";

export default function QA() {
  // const { userState } = useContext(UserContext);

  return (
    <div className="qa">
      <HelmetTemplate
        title="よくある質問"
        description="よくある質問をまとめています。"
      />
      <Navbar />
      <Box
        className={"contents static"}
        minHeight="100vh"
        justifyContent="center"
        textAlign="left"
      >
        <Typography component="h1" variant="h5">
          よくある質問
        </Typography>
        {/* <section>
          <Typography component="p" variant="body2">
            当サイト「amazoncomic」は
            <Link to="https://www.amazon.co.jp/" target="_blank">
              Amazon.co.jp
            </Link>
            で販売している電子書籍媒体コミックのジャンル別売れ筋ランキングやセール情報を紹介しているサイトです。
          </Typography>
        </section> */}
        <section>
          <Typography component="h2" variant="h6">
            更新頻度は？
          </Typography>
          <Typography component="p" variant="body2">
            毎日1時間ごとに最新のデータに更新されます。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            データが更新されない
          </Typography>
          <Typography component="p" variant="body2">
            古いデータがキャッシュされている可能性があります。アプリの場合はホームボタンのダブルタップからアプリをスワイプして終了し、起動しなおしてください。Webブラウザご利用の方は、ブラウザのタブまたはウィンドウを一度閉じてから、新規タブ又はウィンドウ上で
            <Link to="/" target="_blank">
              amazon comic
            </Link>
            を読み込みしなおしてください。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            amazonにログインができない
          </Typography>
          <Typography component="p" variant="body2">
            2段階認証を設定している場合、アプリからだと電話番号認証が止まってしまうケースが報告されています。
            <a href="https://www.amazon.co.jp/" target="_blank">
              amazon.co.jp
            </a>
            の「アカウント」→「ログインとセキュリティ」→「2段階認証の設定」から、2段階認証設定を解除することで、アプリ側からのログインができるようになります。
          </Typography>
        </section>
        <section>
          <Typography component="h2" variant="h6">
            お問い合わせ
          </Typography>
          <Typography component="p" variant="body2">
            お問い合わせはamazoncomicjp@gmail.comまで。
          </Typography>
        </section>
      </Box>
    </div>
  );
}
