import React, { useContext, useRef } from "react";
import ListItem from "./ListItem2";
// import { UserContext } from "./../../context/UserContext";
import { AmazonContext } from "./../../context/AmazonContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TagManager from "react-gtm-module";

export default function ListItems2(props) {
  // const { userState, setUserState } = useContext(UserContext);
  const { amazonState } = useContext(AmazonContext);

  let items = () => {
    if (amazonState.isLoaded) {
      return props.items.Items.map((v, i) => {
        return <ListItem item={v} num={i} key={`listItem_${i}`} />;
      });
    } else {
      return <div className="isLoadedFalse"></div>;
    }
  };
  const clickListTitle = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };

  return (
    <>
      {items()}
      {/* {listItemLast()} */}
    </>
  );
}
