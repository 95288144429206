import React, { createContext, useState } from "react";
export const AmazonContext = createContext();

const AmazonContextProvider = (props) => {
  const [amazonState, setAmazonState] = useState({
    isLoaded: false,
    data: {
      test: "aiueo",
      test2: "aiueoaaa",
    },
    searchIDs: [
      { id: "2430812051", title: "少年マンガ" },
      { id: "2430869051", title: "青年マンガ" },
      { id: "2430765051", title: "少女マンガ" },
      { id: "2430737051", title: "女性マンガ" },
      { id: "3686139051", title: "ミステリー" },
      { id: "3686141051", title: "ロマンス" },
      { id: "3686142051", title: "SFマンガ" },
      { id: "3686137051", title: "格闘技マンガ" },
      { id: "3686143051", title: "スポーツマンガ" },
      { id: "3686140051", title: "ノンフィクション" },
      { id: "3686132051", title: "ファンタジーマンガ" },
      { id: "3686135051", title: "ホラーマンガ" },
      { id: "2430727051", title: "4コママンガ" },
      { id: "3686134051", title: "歴史マンガ" },
      { id: "2293144051", title: "イラスト集・公式本" },
      { id: "3418785051", title: "マンガ雑誌" },
      { id: "3686138051", title: "メディアミックス" },
    ],
  });
  return (
    <AmazonContext.Provider value={{ amazonState, setAmazonState }}>
      {props.children}
    </AmazonContext.Provider>
  );
};

export default AmazonContextProvider;
