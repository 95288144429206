import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import AmazonContextProvider from "./context/AmazonContext";
import UserContextProvider from "./context/UserContext";

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <UserContextProvider>
      <AmazonContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </AmazonContextProvider>
    </UserContextProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
