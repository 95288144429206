import React, { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "./../../context/UserContext";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import useLazyloadRef from "use-lazyload-ref";
import noimage from "../../images/noimage.png";
import TagManager from "react-gtm-module";

gsap.registerPlugin(ScrollToPlugin);

export default function ListItem2(props) {
  const listItemRef = useRef(null);
  let imgUrl = "";
  let imgAlt = props.item.ItemInfo.Title.DisplayValue;
  // let itemHref = props.item.DetailPageURL;
  if (props.item.Images) {
    imgUrl = props.item.Images.Primary.Medium.URL;
  } else {
    imgUrl = noimage;
  }
  const { userState, setUserState } = useContext(UserContext);
  const [ref] = useLazyloadRef();
  const [animation, setAnimation] = useState(null);

  // useEffect(() => {
  //   if (listItemRef.current) {
  //     console.log("useEffect");
  //     gsap.to(listItemRef.current, {
  //       y: 0,
  //       duration: 0.2,
  //       delay: props.num * 0.04,
  //     });
  //   }
  // }, [props.num]);
  const clickLinkToOutside = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };

  const clickListItem = () => {
    // console.log("clickListItem", listItemRef);

    document.querySelectorAll(".listItem").forEach((target) => {
      target.classList.remove("active");
    });
    listItemRef.current.classList.add("active");
    let scrollgap = 310;
    // if (!userState.isEventWindow) {
    //   scrollgap = 156;
    // }
    setUserState({
      ...userState,
      isScrolling: true,
      isEventWindow: true,
      currentContent: props.item,
    });
    const pos = listItemRef.current.offsetTop - scrollgap;
    const tl = gsap.timeline();
    gsap.to(listItemRef.current.parentNode, {
      scrollTo: {
        x:
          listItemRef.current.offsetLeft -
          listItemRef.current.clientWidth * 1.2,
      },
    });
    tl.to(window, {
      duration: 0.3,
      scrollTo: pos <= 0 ? 1 : pos,
      onComplete: () => {
        setUserState({
          ...userState,
          isEventWindow: true,
          currentContent: props.item,
          isScrolling: false,
        });
      },
    });
  };
  // let isUseEffectWorking = false;
  // useEffect(() => {
  //   if (userState.currentContent.ItemInfo) {
  //     clickLinkToOutside(
  //       "clickListItem",
  //       userState.currentContent.ItemInfo.Title.DisplayValue
  //     );
  //   }
  // }, [userState.currentContent.ItemInfo]);

  return (
    <li
      className="listItem"
      onClick={() => {
        clickListItem();
      }}
      ref={listItemRef}
    >
      {/* <div className="listItem__img" href={itemHref} target="_blank"> */}

      <div className="listItem__img">
        <img ref={ref} data-src={imgUrl} alt={imgAlt} />
      </div>
    </li>
  );
}
