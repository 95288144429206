import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CachedIcon from "@material-ui/icons/Cached";
import { Menu as MenuIcon } from "@material-ui/icons";
import MainDrawer from "./MainDrawer";
import { AmazonContext } from "./../context/AmazonContext";
// import { UserContext } from "./../context/UserContext";
import { ReactComponent as Logo } from "../images/logo_amazoncomic.svg";
import TagManager from "react-gtm-module";

export default function PrimarySearchAppBar() {
  const { amazonState } = useContext(AmazonContext);
  const btnReload = useRef();
  const clickLinkToOutside = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };
  // const { userState, setUserState } = useContext(UserContext);
  const reloadPage = () => {
    clickLinkToOutside("reloadPage", amazonState.data.date.lastUpdate);
    btnReload.current.classList.add("active");
    window.location.reload(false);
  };
  const updateText = () => {
    if (amazonState.isLoaded) {
      return (
        <p className="text__update">
          <button onClick={reloadPage} ref={btnReload}>
            <span>{amazonState.data.date.lastUpdate.substring(5)} 更新</span>
            <CachedIcon />
          </button>
        </p>
      );
    } else {
      return (
        <p className="text__update">
          <button className="active">
            <CachedIcon />
          </button>
        </p>
      );
    }
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    // console.log("handleDrawerToggle", mobileOpen);
    if (!mobileOpen) {
      clickLinkToOutside("clickMenuBtn", "menu");
    }
    setMobileOpen(!mobileOpen);
    // setMobileOpen(!userState.isDrawer);
  };
  // const handlerMenuBtn = () => {
  //   console.log("userState.isDrawer", userState.isDrawer);
  //   setUserState({ ...userState, isDrawer: !userState.isDrawer });
  // };
  return (
    <div className={"navBar"}>
      <AppBar position="fixed">
        <div className="mainNav">
          <div className="flex">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="h1">
              <Link to="/" className="logo flex">
                <Logo style={{ height: "33%" }} />
              </Link>
            </Typography>
            {updateText()}
          </div>
        </div>
        <div className="subNav">
          <ul className="flex">
            <li>
              <Link to="/sale" className="flex subNav__sale">
                <span>セール中</span>
              </Link>
            </li>
            <li>
              <Link to="/popular" className="flex subNav__popular">
                <span>高レビュー</span>
              </Link>
            </li>
            <li>
              <Link to="/recommend" className="flex subNav__recommend">
                <span>今おススメ</span>
              </Link>
            </li>
            <li>
              <Link to="/free" className="flex subNav__free">
                <span>無料マンガ</span>
              </Link>
            </li>

            <li>
              <Link to="/new" className="flex subNav__new">
                <span>新着</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/category" className="flex subNav__category">
                <span>カテゴリ</span>
              </Link>
            </li> */}
            {/* <li className={"sortIcon"}>
              <IconButton edge="end" color="inherit">
                <SortIcon />
              </IconButton>
            </li> */}
          </ul>
        </div>
      </AppBar>

      <MainDrawer
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </div>
  );
}
