import React, { useContext, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Navbar from "./../components/Navbar";
import ListItems from "./../components/ListItem/ListItems";
import { AmazonContext } from "./../context/AmazonContext";
import { UserContext } from "./../context/UserContext";
import EventWindow from "./../components/EventWindow";
import { gsap } from "gsap";
import HelmetTemplate from "../components/HelmetTemplate";
import Loading from "./../components/Loading";

export default function Popular() {
  const { amazonState } = useContext(AmazonContext);
  const { userState } = useContext(UserContext);
  const classBox = userState.isEventWindow ? "contents active" : "contents";
  const refContents = useRef(null);
  const contents = () => {
    if (amazonState.isLoaded) {
      const inner = amazonState.searchIDs.map((v, i) => {
        if (amazonState.data.searchItems[`AvgCustomerReviews_${v.id}`]) {
          return (
            <ListItems
              key={`AvgCustomerReviews_${i}`}
              title={v.title}
              items={
                amazonState.data.searchItems[`AvgCustomerReviews_${v.id}`]
                  .SearchResult
              }
            />
          );
        } else {
          return null;
        }
      });
      return (
        <div ref={refContents} className="contents__inner">
          {inner}
        </div>
      );
    } else {
      return <Loading />;
    }
  };

  useEffect(() => {
    gsap.to(window, {
      duration: 0.3,
      scrollTo: 0,
    });
  }, [refContents]);

  return (
    <div className="popular">
      <HelmetTemplate
        title="高レビューkindleマンガ"
        description="ユーザーレビュー高評価の人気kindleコミックを、ジャンル毎に一挙公開！"
      />
      <Navbar />
      <EventWindow />
      <Box
        className={classBox}
        minHeight="100vh"
        justifyContent="center"
        textAlign="center"
      >
        {contents()}
      </Box>
    </div>
  );
}
