import React, { useContext, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Navbar from "./../components/Navbar";
import ListItems from "./../components/ListItem/ListItems2";
import { AmazonContext } from "./../context/AmazonContext";
import { UserContext } from "./../context/UserContext";
import EventWindow from "./../components/EventWindow";
import { gsap } from "gsap";
import HelmetTemplate from "../components/HelmetTemplate";
import Loading from "./../components/Loading";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TagManager from "react-gtm-module";

export default function Sale() {
  const { amazonState } = useContext(AmazonContext);
  const { userState } = useContext(UserContext);
  const classBox = userState.isEventWindow ? "contents active" : "contents";
  const clickListTitle = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };
  const refContents = useRef(null);
  const contents = () => {
    if (amazonState.isLoaded) {
      let listItemsArr = [];
      for (let i = 0; i <= 61; i++) {
        if (
          !amazonState.data.searchItems[`Sale_${i}`] ||
          !amazonState.data.searchItems[`Sale_${i}`].SearchResult
        ) {
          // console.log("error : ", i);
          continue;
        } else {
          const listItem = (
            <ListItems
              title={amazonState.data.searchItems[`Sale_${i}`].title}
              items={amazonState.data.searchItems[`Sale_${i}`].SearchResult}
              key={`Sale_${i}`}
            />
          );
          listItemsArr.push(listItem);
        }
      }
      return (
        <div ref={refContents} className="contents__inner">
          <div className="listItems">
            <h2>
              {/* <a
                href="https://www.amazon.co.jp/s?i=digital-text&rh=n%3A7962654051%2Cp_n_availability%3A-1&linkCode=osi&tag=thirdcandy-22"
                rel="noopener"
                target="_blank"
                class="listItems__title"
              >
                <span>セール＆キャンペーン</span>
              </a>
              <a
                href="https://www.amazon.co.jp/s?i=digital-text&rh=n%3A7962654051%2Cp_n_availability%3A-1&linkCode=osi&tag=thirdcandy-22"
                rel="noopener"
                target="_blank"
                class="listItems__title"
              >
                <span>セール＆キャンペーン</span>
              </a> */}

              <a
                href="https://www.amazon.co.jp/s?i=digital-text&rh=n%3A7962654051%2Cp_n_availability%3A-1&linkCode=osi&tag=thirdcandy-22"
                className="listItems__title"
                target="_blank"
                rel="noopener"
              >
                <span
                  onClick={() => {
                    clickListTitle("clickListTitle", "セール＆キャンペーン");
                  }}
                >
                  セール＆キャンペーン
                </span>
              </a>
              <a
                href="https://www.amazon.co.jp/s?i=digital-text&rh=n%3A7962654051%2Cp_n_availability%3A-1&linkCode=osi&tag=thirdcandy-22"
                className="text__size80 btn"
                target="_blank"
                rel="noopener"
              >
                <span
                  onClick={() => {
                    clickListTitle("clickListTotalResultNum", "全部見る");
                  }}
                >
                  {/* 全
                  {amazonState.data.searchItems.Sale_0.SearchResult.Items
                    ? amazonState.data.searchItems.Sale_0.SearchResult.Items
                        .TotalResultCount
                    : "error"}
                  件 */}
                  もっと見る
                </span>
                <ExitToAppIcon />
              </a>
            </h2>
            {listItemsArr.map((v) => v)}
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  };

  useEffect(() => {
    gsap.to(window, {
      duration: 0.3,
      scrollTo: 0,
    });
  }, [refContents]);

  return (
    <div className="sale listStyle2">
      <HelmetTemplate
        title="セール中のkindleマンガリスト"
        description="現在無料またはセール中のkindleコミックのリストを随時更新!"
      />
      <Navbar />
      <EventWindow />
      <Box
        className={classBox}
        minHeight="100vh"
        justifyContent="center"
        textAlign="center"
      >
        {contents()}
      </Box>
    </div>
  );
}
