import React, { useContext, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Navbar from "./../components/Navbar";
import ListItems from "./../components/ListItem/ListItems";
import { AmazonContext } from "./../context/AmazonContext";
import { UserContext } from "./../context/UserContext";
import EventWindow from "./../components/EventWindow";
import { gsap } from "gsap";
import HelmetTemplate from "../components/HelmetTemplate";
import Loading from "./../components/Loading";

export default function Recommend() {
  const { amazonState } = useContext(AmazonContext);
  const { userState } = useContext(UserContext);
  const classBox = userState.isEventWindow ? "contents active" : "contents";
  const refContents = useRef(null);
  const contents = () => {
    if (amazonState.isLoaded) {
      const inner = amazonState.searchIDs.map((v, i) => {
        if (amazonState.data.searchItems[`Featured_${v.id}`]) {
          return (
            <ListItems
              key={`Featured_${i}`}
              title={v.title}
              items={
                amazonState.data.searchItems[`Featured_${v.id}`].SearchResult
              }
            />
          );
        } else {
          return null;
        }
      });
      const inner2 = () => {
        let inner2_contents = [];
        // console.log(
        //   "amazonState.data.searchItems[`Featured_8121772051`]",
        //   amazonState.data.searchItems[`Featured_8121772051`]
        // );
        if (amazonState.data.searchItems[`Featured_8175818051`]) {
          inner2_contents.push(
            <ListItems
              key={`Featured_8175818051}`}
              title={`話題の異世界マンガ`}
              items={
                amazonState.data.searchItems[`Featured_8175818051`].SearchResult
              }
            />
          );
        }
        if (amazonState.data.searchItems[`Featured_8177117051`]) {
          inner2_contents.push(
            <ListItems
              key={`Featured_8177117051}`}
              title={`今読みたい長編マンガ特集`}
              items={
                amazonState.data.searchItems[`Featured_8177117051`].SearchResult
              }
            />
          );
        }
        // if (amazonState.data.searchItems[`Featured_2293143051`]) {
        //   inner2_contents.push(
        //     <ListItems
        //       key={`Featured_2293143051}`}
        //       title={`200円以下おすすめタイトル`}
        //       items={
        //         amazonState.data.searchItems[`Featured_2293143051`].SearchResult
        //       }
        //     />
        //   );
        // }
        if (amazonState.data.searchItems[`Featured_8177281051`]) {
          inner2_contents.push(
            <ListItems
              key={`Featured_8177281051}`}
              title={`巻数が少なめのオススメマンガ`}
              items={
                amazonState.data.searchItems[`Featured_8177281051`].SearchResult
              }
            />
          );
        }
        return inner2_contents;
      };
      return (
        <div ref={refContents} className="contents__inner">
          {inner2()}
          {inner}
        </div>
      );
    } else {
      return <Loading />;
    }
  };

  useEffect(() => {
    gsap.to(window, {
      duration: 0.3,
      scrollTo: 0,
    });
  }, [refContents]);

  return (
    <div className="recommend">
      <HelmetTemplate
        title="今おススメのマンガ"
        description="今話題の人気kindleコミックをジャンル毎に一挙公開!"
      />
      <Navbar />
      <EventWindow />
      <Box
        className={classBox}
        minHeight="100vh"
        justifyContent="center"
        textAlign="center"
      >
        {contents()}
      </Box>
    </div>
  );
}
