import React, { createContext, useState } from "react";
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [userState, setUserState] = useState({
    isLoaded: false,
    showInstallMessage: false,
    duration: 1000,
    sort: "price",
    cart: 0,
    isScrolling: false,
    isEventWindow: false,
    currentContent: null,
    eventWindowHeight: null,
    function: {
      closeEventWindow: () => {
        setUserState({
          ...userState,
          isEventWindow: false,
          currentContent: null,
          eventWindowHeight: null,
        });
        // if (window.scrollY > 10) {
        document.querySelector(".contents").classList.add("active");
        // }
      },
      handleDrawerToggle: () => {
        setUserState({
          ...userState,
          isDrawer: !userState.isDrawer,
        });
      },
    },
  });
  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
