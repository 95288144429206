import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
// import { useTheme, Drawer } from "@material-ui/core";
// import { fade, makeStyles } from "@material-ui/core/styles";
// import MenuIcon from "@material-ui/icons/Menu";
// import Navbar from "./Navbar";
import Home from "./../pages/Home";
import About from "./../pages/About";
import New from "./../pages/New";
import Popular from "./../pages/Popular";
// import { db } from "./../services/firebase";
import { storage } from "./../services/firebase";
import { UserContext } from "./../context/UserContext";
import { AmazonContext } from "./../context/AmazonContext";
import Swipe from "react-easy-swipe";
import Recommend from "../pages/Recommend";
import Free from "../pages/Free";
import Sale from "../pages/Sale";
import { Helmet } from "react-helmet";
import Aths from "./Aths";
import QA from "../pages/QA";
import axios from "axios";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5FQV48W",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

const clickLinkToOutside = (event, target) => {
  const args = {
    dataLayer: {
      event: event,
      target: target,
      /* can pass more variables here if needed */
    },
    dataLayerName: "PageDataLayer",
  };
  TagManager.dataLayer(args);
};

function App() {
  const { userState, setUserState } = useContext(UserContext);
  const { amazonState, setAmazonState } = useContext(AmazonContext);
  // const [isDomLoaded, setIsDomLoaded] = useState(false);
  // const theme = useTheme();
  let timeoutId;

  // const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };
  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  document.addEventListener("DOMContentLoaded", function () {
    window.onmousewheel = function () {
      const $contents = document.querySelector(".contents");
      if ($contents && $contents.classList.contains("active")) {
        userState.function.closeEventWindow();
      }
    };
    document.getElementById("root").addEventListener("click", () => {
      let flagClose = false;
      if (!userState.isEventWindow) flagClose = true;

      document.querySelectorAll(".listItem").forEach((target) => {
        target.addEventListener("click", (e) => {
          flagClose = false;
          // console.log("listItem! flagClose : ", flagClose);
          // e.stopPropagation();
        });
      });
      const $eventWindow = document.querySelector(".eventWindow");
      if ($eventWindow) {
        $eventWindow.addEventListener("click", (e) => {
          flagClose = false;
          // console.log("eventWindow! flagClose : ", flagClose);
          e.stopPropagation();
        });

        if (flagClose) {
          const $contents = document.querySelector(".contents");
          if ($contents && $contents.classList.contains("active")) {
            userState.function.closeEventWindow();
          }
        }
      }
    });

    document.addEventListener("scroll", () => {
      if (!userState.isEventWindow) {
        const $contents = document.querySelector(".contents");
        if (
          $contents &&
          $contents.classList.contains("active") &&
          window.scrollY <= 0
        ) {
          // console.log("window.scrollY", window.scrollY);

          $contents.classList.remove("active");
          window.scrollTo(0, 0);
        }
      }
      if (window.scrollY <= 0) {
        window.scrollTo(0, 1);
      }
    });

    try {
      const storageRef = storage.ref("jsons/data.json");
      // console.log("storageRef", storageRef);

      storageRef
        .getDownloadURL()
        .then((url) => {
          return axios.get(url);
        })
        .then((res) => {
          const data = res.data;
          // console.log("data", data);
          setAmazonState({ ...amazonState, isLoaded: true, data });
        });
      // db.ref("/")
      //   .once("value")
      //   .then(function (snapshot) {
      //     var data = snapshot.val() || "Not Found";
      //     setAmazonState({ isLoaded: true, data });
      //     // setUserState({ isLoaded: true });
      //     console.log("data", data);
      //   }); // ...
      // const fetchUrl2 = functions.config().amazoncomic.herokuapiurl;

      // const fetchUrl = "https://amazon-heroku-firebase.herokuapp.com/data/";
      // // fetch(fetchUrl)
      // //   .then(function (response) {
      // //     return response.json();
      // //   })
      // //   .then(function (myJson) {
      // //     console.log(JSON.stringify(myJson));
      // //   });
      // axios.get(fetchUrl).then((res) => {
      //   // console.log("res", res);
      //   const data = res.data;
      //   setAmazonState({ isLoaded: false, data });
      // });
    } catch (error) {
      // setAmazonState({ isLoaded: error.message });
      // setUserState({ isLoaded: error.message });
    }
  });

  // useEffect(() => {
  //   console.log("amazonState", amazonState);
  // }, [amazonState]);

  // useEffect(() => {
  //   console.log("userState", userState);
  // }, [setUserState, userState]);
  // let flag_scroll = false;
  // document.addEventListener("scroll", () => {
  //   flag_scroll = true;
  //   if (userState.data.isEventWindow && !userState.data.isScrolling) {
  //     userState.function.closeEventWindow();
  //   }
  //   flag_scroll = false;
  // });

  // let countA = 0;
  // if (userState.isEventWindow && !userState.isScrolling) {
  //   window.addEventListener("scroll", () => {
  //     if (timeoutId) return;
  //     timeoutId = setTimeout(() => {
  //       timeoutId = 0;
  //       // userState.function.closeEventWindow();
  //       if (userState.isEventWindow && !userState.isScrolling) {
  //         countA++;
  //         console.log("userState.data.isScrolling", userState.isScrolling);
  //         console.log("countA", countA);
  //       }
  //     }, 500);
  //   });
  // }

  // const scrollHandler = () => {
  //   console.log("scrollHandler");
  // };

  const onSwipeMove = () => {
    closeEventWindow();
  };

  const closeEventWindow = () => {
    if (userState.isEventWindow && !userState.isScrolling) {
      if (timeoutId) return;
      timeoutId = setTimeout(() => {
        timeoutId = 0;
        // console.log("swipe detected");
        userState.function.closeEventWindow();
      }, 250);
    }
  };
  const routesMain = [
    { path: "/", name: "home", Component: Home },
    { path: "/sale", name: "sale", Component: Sale },
    { path: "/popular", name: "popular", Component: Popular },
    { path: "/recommend", name: "recommend", Component: Recommend },
    { path: "/free", name: "free", Component: Free },
    { path: "/new", name: "new", Component: New },
  ];

  useEffect(() => {
    if (userState.currentContent) {
      if (userState.currentContent.ItemInfo) {
        clickLinkToOutside(
          "clickListItem",
          userState.currentContent.ItemInfo.Title.DisplayValue
        );
      }
    }
  }, [userState.currentContent]);

  return (
    <div className="App">
      <Helmet>
        <title>
          amazoncomic - kindleコミックのセール情報、売上ランキングを更新中!
        </title>
        <meta
          name="description"
          content="amazonのkindleコミックのキャンペーンセール情報や、今話題のコミック、コミックの売り上げランキングを随時配信!"
        />
      </Helmet>
      <Switch>
        {routesMain.map(({ path, name, Component }) => {
          return (
            <Route key={name} exact path={path}>
              <Swipe onSwipeMove={onSwipeMove} allowMouseEvents={true}>
                <Component />
              </Swipe>
            </Route>
          );
        })}

        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/qa">
          <QA />
        </Route>
      </Switch>

      <Aths />
    </div>
  );
}

export default App;
