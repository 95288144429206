import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Divider, List, ListItem, useTheme, Drawer } from "@material-ui/core";
// import ListItemLink from "./Helper/ListItemLink";
// import { staticValue } from "./../staticValue";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import TagManager from "react-gtm-module";

export default function MainDrawer(props) {
  const theme = useTheme();

  // const handleDrawerToggle = () => {
  //   console.log("mobileOpen", mobileOpen);
  //   setMobileOpen(!mobileOpen);
  // };
  const clickLinkToOutside = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };
  return (
    <Drawer
      variant="temporary"
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={props.mobileOpen}
      onClose={props.handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <nav className="drawer">
        <Divider />
        <List className="drawer__subNav" onClick={props.handleDrawerToggle}>
          {/* <NavLink activeClassName="selected" to={`/sale`}>
            <ListItem button>セール中のコミック</ListItem>
          </NavLink>
          <NavLink activeClassName="selected" to={`/popular`}>
            <ListItem button>ユーザー高レビュー</ListItem>
          </NavLink>
          <NavLink activeClassName="selected" to={`/recommend`}>
            <ListItem button>今おススメコミック</ListItem>
          </NavLink>
          <NavLink activeClassName="selected" to={`/free`}>
            <ListItem button>無料/激安コミック</ListItem>
          </NavLink>
          <NavLink activeClassName="selected" to={`/new`}>
            <ListItem button>新着コミック</ListItem>
          </NavLink> */}
          <a
            rel="noopener"
            target="_blank"
            href="https://www.amazon.co.jp/b/?&amp;node=3338926051&amp;ref=kdd_redir&_encoding=UTF8&tag=thirdcandy-22&linkCode=ur2&linkId=5e0080b16edf20f9585089abad89608b&camp=247&creative=1211"
          >
            <ListItem
              button
              className="flex"
              onClick={() => {
                clickLinkToOutside("clickLinkInDrawer", "Kindle日替わりセール");
              }}
            >
              <span>Kindle日替わりセール</span>
              <ExitToAppIcon />
            </ListItem>
          </a>
          <a
            target="_blank"
            href="https://www.amazon.co.jp/kindle-dbs/browse?metadata=cardAppType%3ADESKTOP%24deviceTypeID%3AA2Y8LFC259B97P%24clientRequestId%3A8TRV5ENECTEGZ10TZMAA%24deviceAppType%3ADESKTOP%24ipAddress%3A69.172.157.215%24userAgent%3AMozilla%2F5.0+%28Windows+NT+10.0%3B+Win64%3B+x64%29+AppleWebKit%2F537.36+%28KHTML%2C+like+Gecko%29+Chrome%2F81.0.4044.138+Safari%2F537.36%24deviceFamily%3AWhiskeytown%24cardSurfaceType%3Adesktop%24cardMobileOS%3AUnknown%24countryOfResidence%3AJP%24deviceSurfaceType%3Adesktop&amp;storeType=ebooks&amp;widgetId=unified-ku-storefront-default_KindleUnlimitedGBRRecommenderStrategy_2293143051&amp;pf_rd_t=5601&amp;refTagFromService=kugbr&amp;title=%E3%83%9E%E3%83%B3%E3%82%AC%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E5%95%86%E5%93%81&amp;pf_rd_m=AN1VRQENFRJN5&amp;pageType=kindle&amp;pf_rd_p=13aa1a53-c0ec-459e-b3c9-cadce3fec3d1&amp;sourceType=recs&amp;pf_rd_r=8TRV5ENECTEGZ10TZMAA&amp;pd_rd_wg=jyjUg&amp;pf_rd_s=center-2&amp;ref_=dbs_r_shv_kugbr_s2_13aa1a53-c0ec-459e-b3c9-cadce3fec3d1&amp;page=1&amp;pd_rd_w=onLMa&amp;pf_rd_i=bookshelf&amp;pd_rd_r=fd0e949a-e5f4-4030-90e2-9b61b8619dff&_encoding=UTF8&tag=thirdcandy-22&linkCode=ur2&linkId=7b2e3fe2bd8ef584d3a97c1e72e13712&camp=247&creative=1211"
          >
            <ListItem
              button
              className="flex"
              onClick={() => {
                clickLinkToOutside(
                  "clickLinkInDrawer",
                  "Kindle Unlimited コミック"
                );
              }}
            >
              <span>Kindle Unlimited コミック</span>
              <ExitToAppIcon />
            </ListItem>
          </a>
          <a
            target="_blank"
            href="https://www.amazon.co.jp/gp/bestsellers/books/5326865051?ref=snv_ranking_comic&_encoding=UTF8&tag=thirdcandy-22&linkCode=ur2&linkId=0f698fe19a730497f316f3843bef52d1&camp=247&creative=1211"
          >
            <ListItem
              button
              className="flex"
              onClick={() => {
                clickLinkToOutside("clickLinkInDrawer", "売れ筋ランキング");
              }}
            >
              <span>売れ筋ランキング</span>
              <ExitToAppIcon />
            </ListItem>
          </a>
          <a
            rel="noopener"
            target="_blank"
            href="https://www.amazon.co.jp/b?_encoding=UTF8&tag=thirdcandy-22&linkCode=ur2&linkId=611c8ed2eee5ded52f4bd1703476069e&camp=247&creative=1211&node=2250738051"
          >
            <ListItem
              button
              className="flex"
              onClick={() => {
                clickLinkToOutside("clickLinkInDrawer", "Kindleベストセラー");
              }}
            >
              <span>Kindleベストセラー</span>
              <ExitToAppIcon />
            </ListItem>
          </a>
          <a
            target="_blank"
            href="https://www.amazon.co.jp/b/ref=jp_cbcc_pc_mypnt/ref=s9_acss_bw_sc_pcppp_ar_s1_w?ie=UTF8&amp;node=3036192051&amp;plattr=JPCBCCPCMYP&amp;pf_rd_m=A3P5ROKL5A1OLE&amp;pf_rd_s=merchandised-search-2&amp;pf_rd_r=273QTDP56DDT7EJ4HX5R&amp;pf_rd_t=101&amp;pf_rd_p=fd39d69d-42b3-44ca-a81b-708280aaba65&amp;pf_rd_i=2632478051&_encoding=UTF8&tag=thirdcandy-22&linkCode=ur2&linkId=dc0b27d306c81b38a22b658439df5a29&camp=247&creative=1211"
          >
            <ListItem
              button
              className="flex"
              onClick={() => {
                clickLinkToOutside("clickLinkInDrawer", "2.5%還元amazonクレカ");
              }}
            >
              <span>2.5%還元amazonクレカ</span>
              <ExitToAppIcon />
            </ListItem>
          </a>
        </List>
        <Divider />
        <List onClick={props.handleDrawerToggle}>
          <NavLink activeClassName="selected" to={`/about`}>
            <ListItem button>このアプリについて</ListItem>
          </NavLink>
          <NavLink activeClassName="selected" to={`/qa`}>
            <ListItem button>よくある質問</ListItem>
          </NavLink>
        </List>
        <Divider />

        <div className="drawer__banner flex">
          <iframe
            title="amazon_banner_1"
            src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=20&l=ur1&category=gift_certificates&banner=1GFNZE4YM1HZKFWSAV02&f=ifr&linkID=adee979e49a5a731da6e566e891d90da&t=thirdcandy-22&tracking_id=thirdcandy-22"
            width="120"
            height="90"
            scrolling="no"
            border="0"
            onClick={() => {
              clickLinkToOutside("clickLinkInDrawer", "amazon_banner_1");
            }}
          ></iframe>
          {/* <iframe
            src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=12&l=ur1&category=gift_certificates&banner=1TJ8XM5YGJR5WC15P202&f=ifr&linkID=e5ab093add686b1e4574753fa1c6c3ea&t=thirdcandy-22&tracking_id=thirdcandy-22"
            width="300"
            height="250"
            scrolling="no"
            border="0"
          ></iframe> */}
          {/* <iframe
            src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=20&l=ur1&category=gift_certificates&banner=1GFNZE4YM1HZKFWSAV02&f=ifr&linkID=ac2cf70097c16fbefcb4eefc0dc42f69&t=thirdcandy-22&tracking_id=thirdcandy-22"
            width="120"
            height="90"
            scrolling="no"
            border="0"
          ></iframe> */}
          {/* <iframe
            src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=6&l=ur1&category=kindlerotate&f=ifr&linkID=6034e256a33e375f4f26903235b45db8&t=thirdcandy-22&tracking_id=thirdcandy-22"
            width="120"
            height="150"
            scrolling="no"
            border="0"
          ></iframe> */}
          {/* <iframe
            src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=40&l=ur1&category=digital_software&f=ifr&linkID=f1adb0bd2b27c0a3e6749b8f19161d10&t=thirdcandy-22&tracking_id=thirdcandy-22"
            width="120"
            height="60"
            scrolling="no"
            border="0"
          ></iframe> */}
        </div>
        <footer>
          <ul className="snsIcons">
            <li>
              <a
                href="https://twitter.com/comic_amazon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            </li>
            {/* <li>
              <a
                href="https://twitter.com/comic_amazon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </li> */}
          </ul>
          <div className="copy">&copy;amazon-comic.com</div>
        </footer>
        {/* <List>
          <ListItem button disabled={true}>
            <ListItemText primary="Web design" />
          </ListItem>
          <ListItem button disabled={true}>
            <ListItemText primary="Logo design" />
          </ListItem>
          <ListItem button disabled={true}>
            <ListItemText primary="Photo" />
          </ListItem>
          <ListItem button disabled={true}>
            <ListItemText primary="More about Me" />
          </ListItem>
        </List> */}
      </nav>
    </Drawer>
  );
}
