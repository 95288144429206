import React, { useContext, useRef } from "react";
import ListItem from "./ListItem";
// import { UserContext } from "./../../context/UserContext";
import { AmazonContext } from "./../../context/AmazonContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TagManager from "react-gtm-module";

export default function ListItems(props) {
  // const { userState, setUserState } = useContext(UserContext);
  const { amazonState } = useContext(AmazonContext);

  let items = () => {
    if (amazonState.isLoaded) {
      return props.items.Items.map((v, i) => {
        return <ListItem item={v} num={i} key={`listItem_${i}`} />;
      });
    } else {
      return <div className="isLoadedFalse"></div>;
    }
  };
  const clickListTitle = (event, target) => {
    const args = {
      dataLayer: {
        event: event,
        target: target,
        /* can pass more variables here if needed */
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(args);
  };
  let title = () => {
    if (amazonState.isLoaded) {
      return (
        <h2>
          <a
            href={props.items ? props.items.SearchURL : ""}
            className="listItems__title"
            target="_blank"
            rel="noopener"
          >
            <span
              onClick={() => {
                clickListTitle(
                  "clickListTitle",
                  props.title ? props.title : ""
                );
              }}
            >
              {props.title ? props.title : ""}
            </span>
          </a>
          <a
            href={props.items ? props.items.SearchURL : ""}
            className="text__size80 btn"
            target="_blank"
            rel="noopener"
          >
            <span
              onClick={() => {
                clickListTitle(
                  "clickListTotalResultNum",
                  props.items ? props.items.TotalResultCount : "error"
                );
              }}
            >
              全{props.items ? props.items.TotalResultCount : "error"}件
            </span>
            <ExitToAppIcon />
          </a>
        </h2>
      );
    } else {
      return <div></div>;
    }
  };
  let listItemLast = () => {
    if (amazonState.isLoaded) {
      return (
        <li
          className="listItem"
          onClick={() => {
            clickListTitle("clickListMore", "もっと見る");
          }}
        >
          <a
            href={props.items.SearchURL}
            className="listItem__last"
            target="_blank"
            rel="noopener"
          >
            <span>
              もっと見る
              <br />
              <ExitToAppIcon />
            </span>
          </a>
        </li>
      );
    } else {
      return <li></li>;
    }
  };
  return (
    <div className="listItems">
      {title()}
      <ul>
        {items()}
        {listItemLast()}
      </ul>
    </div>
  );
}
