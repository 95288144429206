import React from "react";
import { Helmet } from "react-helmet";
import og from "../images/og.png";

export default function HelmetTemplate(props) {
  return (
    <Helmet
      title={`${props.title} | amazoncomic`}
      meta={[
        { name: "description", content: props.description },
        { name: "author", content: "amazon comic" },
        { property: "og:title", content: props.title },
        { property: "og:description", content: props.description },
        { property: "og:type", content: "website" },
        { property: "og:image", content: og },
        { property: "og:url", content: "https://amazon-comic.com" },
        { property: "og:image:width", content: "1200" },
        { property: "og:image:height", content: "630" },
        { property: "og:image:alt", content: "amazon comic" },
        { name: "twitter:site", content: "@comic_amazon" },
        {
          name: "twitter:image",
          content: "https://amazon-comic.com/logo512.png",
        },
        { name: "twitter:image:width", content: "512" },
        { name: "twitter:image:height", content: "512" },
        { name: "twitter:image:alt", content: "amazon comic" },
        { name: "twitter:card", content: "app" },
        { name: "twitter:app:country", content: "ja" },
        { name: "twitter:title", content: `${props.title}` },
        { name: "twitter:description", content: `${props.description}` },
        { name: "twitter:url", content: "https://amazon-comic.com" },
        { name: "twitter:app:url:iphone", content: "https://amazon-comic.com" },
        { name: "twitter:app:url:ipad", content: "https://amazon-comic.com" },
        {
          name: "twitter:app:url:googleplay",
          content: "https://amazon-comic.com",
        },
        { name: "twitter:app:name:iphone", content: "amazon comic" },
        { name: "twitter:app:name:ipad", content: "amazon comic" },
        { name: "twitter:app:name:googleplay", content: "amazon comic" },
      ]}
    />
  );
}
