import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { orange, lightBlue } from "@material-ui/core/colors/";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: lightBlue,
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiCard: {},
  },
});

export default theme;
